<template>
  <div class="container-etapa">
     <div class="d-flex box-geral ver-desktop container">
        <div v-if="$route.params.categoria != 'e-stock'" class="faixa-etapa">
            <div class="d-flex proximaEtapa">
                <div class="d-flex dataProximaEtapa">
                    <div class="etapa-box">
                        {{numeroEtapa}}
                    </div>
                    
                    <div class="dataEtapa" v-if="etapa"> 
                        <strong v-if="$route.params.categoria == 'proseries'">{{etapa.dataDia -1}} e</strong>
                        <label>
                        <strong style="margin-left: 4px;"> {{etapa.dataDia}}</strong> DE {{etapa.dataMes}} DE <strong>{{etapa.dataAno}}</strong>    
                        </label>
                    </div>
                </div>
                
                <div>
                    <img
                        src="@/assets/img/carbono.png"
                        alt="Selo Evento CO2 carbono"
                        class="carbono"
                        />
                </div>
            </div>

            <div class="d-flex circuito-box"  v-if="etapa">
                <img
                  :src="etapa.imagemCircuito"
                  alt="Imagem do circuito"
                  class="imgCircuito"
                />
                <div class="nome-circuito" v-if="$route.params.categoria != 'formula-4'">
                    <h3>{{etapa.circuito}}</h3>
                    <p>{{etapa.cidade}}</p>
                </div>

                <div class="nome-circuito-f4" v-else>
                    <h3>{{etapa.circuito}}</h3>
                    <p>{{etapa.cidade}}</p>
                </div>
            </div>
        </div>
        <div v-if="$route.params.categoria != 'e-stock'" class="contador"> 
            <div class="d-flex contador-etapa">
                <div class="boxContador">
                    <p class="numcontador">{{ days >= 0 ? days : '00'}}</p> <p>dias</p> 
                </div>
                <div class="boxContador">
                   <p class="numcontador"> {{ hours >= 0 ? hours : '00'}}</p> <p> horas</p> 
                </div>
                <div class="boxContador">
                   <p class="numcontador"> {{ minutes >=0 ? minutes : '00' }} </p> <p>min</p> 
                </div>
                <div class="boxContador">
                   <p class="numcontador"> {{ seconds >= 0? seconds : '00' }}</p> <p> seg</p> 
                </div>
            </div>
              
            <div v-if="$route.params.categoria != 'e-stock' " class="box-btn-programacao">
            <a v-if="etapa && etapa.urlTickets"
                :href="etapa.urlTickets"
                target="_blank"
                class="garantirIngresso"
                >GARANTIR MEU INGRESSO</a
            >

              <a v-else
                href=""
                target="_blank"
                class="btn-desativado"
                >INGRESSOS EM BREVE</a
            >

             <router-link :to="`/stockcar/${$route.params.categoria}/programacao/`" class="btn-programacao" >VER PROGRAMAÇÃO </router-link>
            </div>
            
        </div>
     </div>

      <div class="d-flex box-geral  ver-mobile">
        <div class="faixa-etapa">
            <div class="d-flex proximaEtapa">
                <div class="d-flex dataProximaEtapa">
                    <div class="etapa-box">
                        {{numeroEtapa}}
                    </div>
                    
                     <div class="d-flex contador-etapa">
                        <div class="boxContador">
                            <p class="numcontador"><strong>{{ days }}</strong> d</p> 
                        </div>
                        <div class="boxContador">
                            <p class="numcontador"> <strong>{{ hours }}</strong> h</p> 
                        </div>
                        <div class="boxContador"> 
                            <p class="numcontador"> <strong>{{ minutes }}</strong> min</p> 
                        </div>
                        <div class="boxContador">
                            <p class="numcontador"><strong> {{ seconds }}</strong> seg</p> 
                        </div>
                    </div>

                    
                </div>
  
            </div>

            <div class="d-flex circuito-box" v-if="etapa">  
               <div class="circuitomobile">
                 <img
                  :src="etapa.imagemCircuito"
                  alt="Imagem do circuito"
                  class="imgCircuito"
                />

                    <img
                        src="@/assets/img/carbono.png"
                        alt="Selo Evento CO2 carbono"
                        class="carbono"
                        />
               </div>
                <div class="nome-circuito">
                    <div class="dataEtapa" v-if="etapa"><strong>{{etapa.dataDia}}</strong> DE {{etapa.dataMes}} DE <strong>{{etapa.dataAno}}</strong></div>
                    <h3 class="nome-circuito" v-if="$route.params.categoria != 'formula-4'">{{etapa.circuito}}</h3>
                    <h3 class="titulo-mob-f4" v-else>{{etapa.circuito}}</h3>
                    <p class="nome-circuito" v-if="$route.params.categoria != 'formula-4'">{{etapa.cidade}}</p>
                    <p class="texto-mob-f4" v-else>{{etapa.cidade}}</p>
                      <a v-if="etapa && etapa.urlTickets"
                            :href="etapa.urlTickets"
                            target="_blank"
                            class="btn garantirIngresso"
                            >GARANTIR MEU INGRESSO</a
                        >

                        <a v-else
                            href=""
                            target="_blank"
                            class="btn-desativado"
                            >INGRESSO EM BREVE</a
                        >
                         <router-link :to="`/stockcar/${$route.params.categoria}/programacao/`" class="btn-programacao" >VER PROGRAMAÇÃO </router-link>
                </div>
            </div>
        </div>
     </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";
export default {
  name: "EtapaHome",
  data() {
    return {
      loading: false,
      etapa: null,
      idCircuito: null,
      countdown: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
   computed: {
     numeroEtapa() {
      if (!this.etapa) return '';
      const numero = this.etapa.etapa.match(/\d+/);
      return numero ? "Etapa " + numero[0] : this.etapa.etapa;
    }
 },
  methods: {
    fetchProximaEtapa(id_categoria) {
        this.loading = true;
        
        api
          .get(`Etapa/proxima/categoria/${id_categoria}`)
          .then((resp) => {
            this.etapa = resp.data;
            console.log(this.etapa)
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.loading = false;
          });
      
    }
  },
  mounted() {
    this.fetchProximaEtapa(
      mapCategorias[this.$route.params.categoria || "proseries"]
    );
  },

   created() {
    this.countdown = setInterval(() => {
      const now = new Date().getTime();
      const difference = new Date(this.etapa.dataEtapa).getTime() - now;
      this.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
    }, 1000);

  },
  beforeDestroy() {
    clearInterval(this.countdown);
  },

};
</script>

<style scoped>

.numcontador {
    font-size: 43px !important;
}

.contador-etapa {
    gap: 10px;
    justify-content: center;
}

.contador-etapa p {
    font-family: "MetronicPro-Regular";
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: -9px;
    color: var(--title-color);
}
.dataEtapa {
    font-style: italic;
    color: var(--title-color);
    font-size: 18px;
    font-family: "MetronicPro-Light";
}

.etapa-box {
    text-transform: uppercase;
    width: auto;
    color: #000000;
    font-family: "MetronicPro-Bold";
    background-color: var(--primary-color);
    font-size: 20px;
    padding: 5px 20px;
    position: relative;
    display: inline-block;
}
/*
.etapa-box:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 110px;
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent var(--primary-color) var(--primary-color);
}*/

.proximaEtapa {
    border-bottom: 1px solid var(--primary-color);
    justify-content: space-between;
}

.imgCircuito, .carbono {
    width: 110px;
}

.nome-circuito h3 {
    letter-spacing: 0px;
    margin-bottom: 0px;
    font-family: "Uniwars-W00-Black";
    color: var(--title-color);
    font-size: 36px;
}

.nome-circuito p {
    margin-bottom: 0px;
    font-family: "UniwarsW00Regular";
    color: var(--title-color);
    font-size: 20px;
}

.titulo-mob-f4 {
    font-family: "Venera-900" !important;
}

.texto-mob-f4 {
    font-family: "Venera-300" !important;
}

.nome-circuito-f4 h3 {
    letter-spacing: 0px;
    margin-bottom: 0px;
    font-family: "Venera-900";
    color: var(--title-color);
    font-size: 36px;
}

.nome-circuito-f4 p {
    margin-bottom: 0px;
     font-family: "Venera-300";
    color: var(--title-color);
    font-size: 20px;
}

.circuito-box {
    align-items: center;
    gap: 60px;
}

.box-geral {
    gap: 20px;
    align-items: baseline;
    justify-content: space-between;
}

.faixa-etapa {
    width: 70%;
}

.dataProximaEtapa {
    gap: 30px;
}

.garantirIngresso {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    font-family: "MetronicPro-Bold";
    font-size: 16px;
    padding: 15px 75px;
    margin: 0 auto;
    display: table;
    margin-top: 15px !important;
}

.garantirIngresso:hover {
    background-color: var(--primary-color);
    color: var(--title-color);
}

.container-etapa {
    background-color: var(--bg-container-etapa);
    padding: 15px 0px 10px 0px;
}


.btn-desativado {
  margin-top: 20px;
  font-family: "MetronicPro-Bold";
  font-size: 13px;
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 6px;
  text-decoration: none;
  border: 1px solid;
  text-align: center;
  color: #7c7c7c;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  cursor: default;
  pointer-events: none;
  border: 1px solid grey !important;
  color: grey  !important;
}

.btn-programacao {
  color: var(--primary-color);
    border: 1px solid var(--primary-color);
    font-size: 13px !important;
    margin-top: 20px;
    font-family: "MetronicPro-Bold";
    padding: 10px 15px;
    background-color: transparent;
    border-radius: 6px;
    text-decoration: none;
    border: 1px solid;
    text-align: center;
    transition: 0.3s;
    display: flex;
    justify-content: center;
}


.box-btn-programacao {
    justify-content: space-between;
    display: flex;
}

@media (max-width: 990px) {
    .ver-desktop {
        display: none !important;
    }

    .faixa-etapa {
        width: 100%;
    }

    .etapa-box {
        font-size: 13px;
        width: auto;
        padding: 4px 10px;
    }

    .etapa-box:after {
        left: 90px;
        border-width: 15px;
    }

    .contador-etapa {
        gap: 10px;
    }

    .contador-etapa p {
        text-transform: lowercase;
        font-size: 15px !important;
    }

    .dataProximaEtapa {
        gap: 10px;
        width: 100%;
        justify-content: space-between;
    }

    .nome-circuito h3 {
        font-size: 24px;
    }

    .nome-circuito p, .dataEtapa {
        font-size: 15px;
    }

    .circuito-box {
        gap: 15px;
    }

    .nome-circuito {
        margin-top: 15px;
    }

    .circuitomobile {
         width: 120px;
    }

    .container-etapa {
        padding: 20px 10px;
        margin-bottom: 30px;
    }
    .circuito-box {
        margin-bottom: 10px;
    }

    .btn-desativado {
        padding: 10px 10px;
    }
}

@media (min-width: 991px) {
    .ver-mobile {
        display: none !important;
    }

    .boxContador {
        background-color: var(--text-color);
        width: 80px;
        padding: 0px 0px 25px 0px;
    }
}
</style>